const JSONVieweThemes = {
  whiteTheme: {
    scheme: "custom-white-background",
    author: "custom",
    base00: "#ffffff", // White background
    base01: "#f0f0f0", // Slightly darker for subtle contrast
    base02: "#d8d8d8", // Light gray for secondary elements
    base03: "#a0a0a0", // Medium gray for comments, meta
    base04: "#707070", // Dark gray for faint text
    base05: "#333333", // Darker text for better contrast on white
    base06: "#292929", // Even darker for strong emphasis
    base07: "#1d1f21", // Near-black for primary text
    base08: "#e74c3c", // Red for errors, warnings
    base09: "#e67e22", // Orange for highlights, warnings
    base0A: "#f1c40f", // Yellow for numbers, identifiers
    base0B: "#127912", // Green for success, booleans
    base0C: "#1abc9c", // Cyan for special elements, code
    base0D: "#3498db", // Blue for keywords, links
    base0E: "#9b59b6", // Purple for constants, decorators
    base0F: "#e74c3c", // Red for errors
  },
};

export { JSONVieweThemes };
