import moment from "moment";
import React, { createContext, useState, useEffect } from "react";
import copy from "copy-to-clipboard";
const GLobalStoreContext = createContext();

export const useGlobalStore = () => {
  return React.useContext(GLobalStoreContext);
};

export const GLobalStoreProvider = ({ children }) => {
  // --------------- ** STATES ** -----------------

  //---- mobile app info like version ----
  // const [appInfo, setAppInfo] = useState({
  // });
  const [statusBarHeight, setStatusBarHeight] = useState(8);

  const [storeStates, setStoreStates] = useState({
    isGoogleMapsScriptLoaded: false,
    currentLocation: null,
    onScreenDraggableVideoStates: {
      isRender: true,
    },
    OSPVideos: null,
    HIWExpandedView: false,
    closedOSPVideoPages: [],
    testimonials: null,
    HIWvideos: null,
  });

  // ----- user's orders list
  const [allOrders, setAllOrders] = useState(null);

  const [userStates, setUserStates] = useState({
    defaultReadyTime: "",
    defaultArrivingTime: "",
    defaultCelebrationDate: moment().add(1, "days").toISOString(),
  });

  // ------- date and slot informations --------------------
  const [orderInfo, setOrderInfo] = useState({
    convenienceFee: 0,
    selectedTimeSlot: "",
    selectedDate: moment().add(1, "day").format("DD-MM-YYYY"), // it will set tomorrow date
  });

  const [wishlistedArray, setWishlistedArray] = useState([]); // stores the wishlisted product _id
  const [wishlistedArrayObj, setWishlistedArrayObj] = useState([]); // stores the wishlisted product's complete info

  const [freebiesArray, setFreebiesArray] = useState({
    isLoading: false,
    data: null,
  });

  //------------------ ** USE EFFECTS ** ------------------

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        if (window.ReactNativeWebView) {
          const receivedData = JSON.parse(event.data);
          const responseMessage = {
            data: "Received statusBarHeight",
            dataReceived: receivedData,
          };
          window.ReactNativeWebView.postMessage(
            JSON.stringify(responseMessage)
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleContentLoadOnUserChange = async () => {
    const user = JSON.parse(localStorage?.getItem("user"));

    // wishlist first time load
    user && toggleWishlistItem();
  };

  const toggleWishlistItem = async (product, type) => {
    try {
      const { handleWishlistedProducts } = await import("../api/wishlistApi");
      const res = await handleWishlistedProducts({
        mode: type,
        productCode: product?._id,
      });

      if (res?.isSuccess) {
        setWishlistedArrayObj(res.wishlistedProducts);
        const resWishlistedArray = res.wishlistedProducts.map(
          (curr) => curr._id
        );
        setWishlistedArray(resWishlistedArray);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //  send message to native
  /*
      sendMessageToNative({
        action: "",
        trigger:"vibration",
        impactLevel:"light",  // medium // heavy
      });
  */
  const sendMessageToNative = (props) => {
    const message = {
      ...props,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  const handleVibrate = () => {
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
  };

  // ------ overriding the console to read messages from react native
  useEffect(() => {
    // Call the function to override console.log
    overrideConsoleLog();

    window.sendMessageToNative = (props) => {
      const message = {
        ...props,
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(message));
    };

    window.openUrl = (url) => {
      console.log("openUrl", url);
      sendMessageToNative({
        action: "openUrl",
        url: url,
      });
    };
    window.share = (url) => {
      window.copyContent(url, "Decor link copied");
      sendMessageToNative({
        action: "share",
        url: url,
      });
    };

    window.copyContent = (content, msg) => {
      copy(content);
      window.toast(msg);
    };

    // eslint-disable-next-line
  }, []);

  function extractDataFromMessage(message) {
    const startMarker = "datastart=";
    const endMarker = "dataend";

    const startIndex = message.indexOf(startMarker);
    const endIndex = message.indexOf(endMarker);

    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      try {
        const dataString = message.substring(
          startIndex + startMarker.length,
          endIndex
        );
        const validJsonString = dataString.replace(/'/g, '"');

        const data = JSON.parse(validJsonString);

        return data;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }

    return null;
  }

  function overrideConsoleLog() {
    const originalConsoleLog = console.log;

    console.log = function (message) {
      if (
        message &&
        typeof message === "string" &&
        message.includes("reactNativeMessage")
      ) {
        const extractedData = extractDataFromMessage(message);

        if (extractedData) {
          if (extractedData?.lat) {
            setStoreStates((p) => ({
              ...p,
              currentLocation: extractedData,
            }));
          }
        }
      }

      originalConsoleLog.apply(console, arguments);
    };
  }

  // ----------------------------------------------------------------

  return (
    <GLobalStoreContext.Provider
      value={{
        allOrders,
        setAllOrders,
        orderInfo,
        setOrderInfo,
        wishlistedArray,
        setWishlistedArray,
        wishlistedArrayObj,
        setWishlistedArrayObj,
        toggleWishlistItem,
        freebiesArray,
        setFreebiesArray,
        userStates,
        setUserStates,
        // appInfo,
        sendMessageToNative,
        handleVibrate,
        statusBarHeight,
        storeStates,
        setStoreStates,
        handleContentLoadOnUserChange,
      }}
    >
      {children}
    </GLobalStoreContext.Provider>
  );
};

export default GLobalStoreContext;
