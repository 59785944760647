import { insideHubBoundary } from "./availablityChecks";
import { serviceableCitiesLocationData } from "../../utils/eboUtils";

export const handleDefaultLocationFetch = async (locationData, ipInfo) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));
    let isDefaultAddress = false;
    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    }

    if (!selectedLocation?.lat) {
      if (ipInfo?.state?.toLowerCase()?.trim() === "west bengal") {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "siliguri"
        );
      } else if (ipInfo?.city?.toLowerCase()?.includes("pune")) {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "pune"
        );
      } else {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "mumbai"
        );
      }
      isDefaultAddress = true;
    }

    // Check if the location falls within a specific hub's boundary
    let isPointInPolygon = await insideHubBoundary([
      selectedLocation.lng,
      selectedLocation.lat,
    ]);

    // If not, set default location for Mumbai and check again
    if (!isPointInPolygon.isInside) {
      selectedLocation = serviceableCitiesLocationData.find(
        (_) => _.city === "mumbai"
      );
      isPointInPolygon = await insideHubBoundary([
        selectedLocation.lng,
        selectedLocation.lat,
      ]);
    }

    // Update localStorage and state with the selected location
    localStorage.setItem(
      "U_LOCATION",
      JSON.stringify({ ...selectedLocation, isDefault: isDefaultAddress })
    );

    return {
      hubId: isPointInPolygon.foundInWhichHub,
      locationObject: {
        ...selectedLocation,

        hubId: isPointInPolygon.foundInWhichHub,
      },
    };
  } catch (error) {
    console.error(error);
  }
};
