import React, { createContext, useContext, useState } from "react";
import AuthContext from "./AuthContext.js";
import axios from "axios";
import CompRenderingContext from "./CompRenderingContext.js";
import { get_address_bq } from "../api/addressApi.js";

const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addressSpinner, setaddressSpinner] = useState(true);
  const {
    initialState: { user },
    logout,
  } = useContext(AuthContext);
  const [allAddress, setAllAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const { setToastMsg } = useContext(CompRenderingContext);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const getAddress = async () => {
    try {
      setaddressSpinner(true);
      let filteredAddresses = null;
      const res = await get_address_bq({
        queryObj: { userId: user.id },
        projectionString: "",
      });

      if (res.isInvalidToken) {
        logout();
      } else {
        if (res.isSuccess) {
          filteredAddresses = res.result.filter((_) => _.locationv2?.lat);
          setAllAddress(filteredAddresses);
        } else {
          console.error(res.message);
        }
      }
      setaddressSpinner(false);

      return filteredAddresses;
    } catch (e) {
      console.log(e.message);
    }
  };
  const fetchAddressData = async () => {
    if (user?.token && !allAddress) {
      const userAddresses = await getAddress();

      return userAddresses;
    }
    return allAddress;
  };
  /* edit address */
  const editAddress = async (token, formData) => {
    const {
      addressId,
      OwnerName,
      mobile1,
      mobile2,
      state,
      pincode,
      city,
      address1,
      address2,
      addressType,
    } = formData;
    try {
      setaddressSpinner(true);
      const res = await axios.patch(
        `${BASE_URL}/api/users/address/edit-address`,
        {
          addressId: addressId,
          addressLabel: addressType,
          ownerName: OwnerName,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          pinCode: parseInt(pincode, 10),
          mobile: [mobile1, mobile2],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setToastMsg({
          msg: "Address edited Successfully",
          isRender: true,
        });
      } else {
        console.log("Something Went Wrong! Try again later");
      }
      getAddress();

      return res.data;
    } catch (e) {
      console.log(e);
    } finally {
      setaddressSpinner(false);
      setIsAddAddress(false);
    }
  };
  /* remove address */
  const removeAddress = async (addressID) => {
    try {
      setaddressSpinner(true);
      const res = await axios.delete(
        `${BASE_URL}/api/users/address/delete-address/${addressID}`,

        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      if (res.status === 200) {
        setToastMsg({
          msg: "Address removed Successfully",
          isRender: true,
        });
      } else {
        console.log("Something Went Wrong! Try again later");
      }
      getAddress();
      return res.data;
    } catch (e) {
      console.log(e);
    } finally {
      setaddressSpinner(false);
    }
  };

  // get address by address id
  const getAddressById = async (addressId) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/users/address/getAddressById/${addressId}`,
        {
          headers: {
            Authorization: "Bearers " + user.token,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //  form validation function
  const formValidation = (form) => {
    const {
      OwnerName,
      mobile1,

      pincode,
      city,
      state,
      address1,

      addressType,
    } = form;
    const mobileNumberPattern = /^[0-9]{10}$/;
    const pinCodePattern = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      OwnerName === "" ||
      mobile1 === "" ||
      pincode === "" ||
      city === "" ||
      state === "" ||
      address1 === "" ||
      addressType === "" ||
      !OwnerName ||
      !mobile1 ||
      !pincode ||
      !city ||
      !state ||
      !address1 ||
      !addressType
    ) {
      setToastMsg({
        msg: "Please fill all the credentials",
        isRender: true,
      });
      return false;
    } else if (!mobileNumberPattern.test(mobile1)) {
      setToastMsg({
        msg: "Please provide us a valid number",
        isRender: true,
      });
      return false;
    } else if (!pinCodePattern.test(pincode)) {
      setToastMsg({
        msg: "Please provide us a valid pincode",
        isRender: true,
      });
      return false;
    }
    return true;
  };

  return (
    <AddressContext.Provider
      value={{
        addressSpinner,
        allAddress,
        editAddress,
        removeAddress,
        selectedAddress,
        setSelectedAddress,
        isAddAddress,
        setIsAddAddress,
        formValidation,
        getAddressById,
        getAddress,
        fetchAddressData,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export default AddressContext;
