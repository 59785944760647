import { useContext, memo, useRef, useEffect, useState } from "react";
import ProductPreviewContext from "../../context/ProductPreviewContext";
import ClipLoader from "react-spinners/ClipLoader";
import ProductCard from "../cards/ProductCard";
import CustomBanner from "./CustomBanner";
import PageEndBanner from "./PageEndBanner";
import usePullToRefresh, { RefreshLoader } from "../../hooks/usePullToRefresh";
import HIWvideoPreview2 from "../videoComp/HIWvideoPreview2";

const ProductList = ({
  containerRef,
  onProductClickCallBack,
  topPadding,
  products,
  dataRefresh,
  inPreviewCardCallBack,
}) => {
  const loadMoreDiv = useRef(null);

  const [refereshStates, setRefereshStates] = useState({});

  usePullToRefresh(
    containerRef,
    () => {
      dataRefresh();
    },
    (params) => {
      setRefereshStates(params);
    }
  );

  // -------------------- context --------------------
  const {
    currPage,
    setCurrPage,
    currUserPos,
    setCurrUserPos,
    productListingpageBanners,
  } = useContext(ProductPreviewContext);

  useEffect(() => {
    setTimeout(() => {
      if (containerRef?.current && currUserPos > 600)
        containerRef.current.scrollTop = currUserPos;
    }, 1000);

    // eslint-disable-next-line
  }, [currUserPos]);

  useEffect(() => {
    !currPage.isLast && currPage.page !== 0 && dataRefresh();
    // eslint-disable-next-line
  }, [currPage]);

  const loadMoreData = async () => {
    setCurrPage((prev) => {
      return {
        page: prev.page + 1,
        isLast: false,
      };
    });
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreData();
        }
      });
    },
    {
      threshold: 0,
      // rootMargin: "500px",
    }
  );

  useEffect(() => {
    const container = loadMoreDiv.current;
    setTimeout(() => {
      observer.observe(container);
    }, 1000);

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        rowGap: ".875rem",
        paddingTop: topPadding + 110 + "px",
      }}
      className="flex  scroll-bar-remove fixed top-0 right-0 left-0 bottom-0 flex-wrap justify-evenly overflow-y-scroll "
    >
      <div className="relative w-full bottom-[2.5rem] ">
        <RefreshLoader refereshStates={refereshStates} />
      </div>

      {products.map((product, index) => (
        <>
          <ProductCard
            index={index}
            key={`${product._id}`}
            inPreviewCardCallBack={inPreviewCardCallBack}
            setCurrUserPos={setCurrUserPos}
            product={product}
            width={"47%"}
            min_width={"47%"}
            onProductClickCallBack={onProductClickCallBack}
          />
          {index === 1 && <HIWvideoPreview2 />}
          {index !== 0 && (index + 1) % 8 === 0 && (
            <CustomBanner
              productListingpageBanners={productListingpageBanners}
              idx={index + 1}
            />
          )}
        </>
      ))}
      <div
        ref={loadMoreDiv}
        className=" relative flex w-[99%] h-[10rem]  justify-center pt-2"
      >
        {!currPage.isLast ? (
          <ClipLoader size={25} color="#2136d4" />
        ) : (
          <PageEndBanner />
        )}
      </div>
    </div>
  );
};

export default memo(ProductList);
